import * as React from "react";
import { Anchor, Button, Heading, Paragraph } from "grommet";

const SpecializedProfessionalsPage = () => {
  return (
    <>
      <Heading level={2} style={{ color: "#3600b2" }}>Profissionais Especializados</Heading>
      <Paragraph fill>
        <strong style={{color:"#3600b2"}}>Encontrar profissionais</strong> especializados e capacitados na área de TI é uma <strong style={{color:"#3600b2"}}>tarefa extremamente complexa e custosa</strong>, quando buscamos profissionais com <strong style={{color:"#3600b2"}}>especialização em dados e transformação digital</strong> (arquitetos de dados, engenheiros de dados, cientistas de dados, especialistas em inteligência artificial, especialistas em governança de dados, DPOs, desenvolvedores frontend e/ou backend, entre outros), essa <strong style={{color:"#3600b2"}}>complexidade aumenta exponencialmente.</strong></Paragraph>
      <Paragraph fill>
      <strong style={{color:"#3600b2"}}>Nossa área de Recrutamento e Seleção</strong> tem a missão de <strong style={{color:"#3600b2"}}>apoiar</strong> de forma ágil e profissional nossos <strong style={{color:"#3600b2"}}>clientes e parceiros</strong> nessa difícil <strong style={{color:"#3600b2"}}>busca por talentos</strong>.  
      Oferecemos um <strong style={{color:"#3600b2"}}>atendimento exclusivo e personalizado</strong> baseado no <strong style={{color:"#3600b2"}}>modelo ágil</strong>, para atender às necessidades de cada cliente, com células dedicadas, disponibilizando consultores especialistas, que atuarão de maneira consultiva e com foco <strong style={{color:"#3600b2"}}>total no resultado</strong>.
      </Paragraph>
      <Paragraph fill>
        <strong id="Outsourcing" style={{ color: "#3600b2" }}>Outsourcing</strong>
        <ul style={{marginBottom: "5px"}}>
          <li>Ofertamos aos clientes a possibilidade de <strong style={{color:"#3600b2"}}>transferir</strong> toda a <strong style={{color:"#3600b2"}}>complexidade</strong> de implementação de <strong style={{color:"#3600b2"}}>projetos de data</strong> para o <strong style={{color:"#3600b2"}}>nosso centro de desenvolvimento</strong>, onde possuímos profissionais de diversos perfis aptos a implementarem as soluções de nossos clientes de <strong style={{color:"#3600b2"}}>forma ágil e assertiva</strong>, orquestrando dados, ferramentas, frameworks, códigos e ambientes do início ao fim.</li>
        </ul>
      </Paragraph>
      <Paragraph fill style={{textAlign: "center"}}>
        <a href="/outsourcing"><Button type="submit" label="SAIBA MAIS" primary size="small" style={{width: "250px"}} /></a>{" "}
        <a href="/contact"><Button type="submit" label="CONTRATAR" primary size="small" style={{width: "250px"}}/></a>
      </Paragraph>

      <Paragraph fill>
        <strong id="BodyShop" style={{ color: "#3600b2" }}>Body Shop</strong>
        <ul style={{marginBottom: "5px"}}>
          <li>Oferecemos <strong style={{color:"#3600b2"}}>complementação de equipe</strong> aos nossos clientes, com <strong style={{color:"#3600b2"}}>profissionais capacitados</strong> para atender suas demandas especificas, realizamos <strong style={{color:"#3600b2"}}>avaliações estruturadas de desempenho, monitorando e acompanhando continuamente a performance dos profissionais,</strong> garantindo assim que ele <strong style={{color:"#3600b2"}}>performem e entreguem os resultados esperados</strong>.</li>
        </ul>
      </Paragraph>
      <Paragraph fill style={{textAlign: "center"}}>
        <a href="/outsourcing"><Button type="submit" label="SAIBA MAIS" primary size="small" style={{width: "250px"}} /></a>{" "}
        <a href="/contact"><Button type="submit" label="CONTRATAR" primary size="small" style={{width: "250px"}}/></a>
      </Paragraph>

      <Paragraph fill>
        <strong id="Hunting" style={{ color: "#3600b2" }}>Hunting</strong>
        <ul style={{marginBottom: "5px"}}>
          <li>Após uma rigorosa <strong style={{color:"#3600b2"}}>triagem comportamental, técnica, e psicológica</strong>, realizada pelo nosso time de Recrutamento e Seleção, conseguimos atender à necessidades muito especificas encontrando o <strong style={{color:"#3600b2"}}>candidato ideal para sua necessidade</strong>.</li>
        </ul>
      </Paragraph>
      <Paragraph fill style={{textAlign: "center"}}>
        <a href="/hunting"><Button type="submit" label="SAIBA MAIS" primary size="small" style={{width: "250px"}} /></a>{" "}
        <a href="/contact"><Button type="submit" label="CONTRATAR" primary size="small" style={{width: "250px"}}/></a>
      </Paragraph>

    </>
  );
};

export default SpecializedProfessionalsPage;
